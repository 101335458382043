<template>
    <div class="flex flex-col gap-4 w-full">
      <c-card class="w-full flex flex-col p-5 pt-0">
          <CardFooter
            printcard
            reloadcard
            :show-search="true"
            @actionModal="onFilter"
            @reload="$queryBuilder({}, getSettingTimeTracking)"
            @sortType="$queryBuilder({ sort: $event }, getSettingTimeTracking)"
            @searchResult="$queryBuilder({ search: $event }, getSettingTimeTracking)"
          >
            <template v-slot:rightItems>
              <div class="ml-3">
                <c-button
                  label="Add Employee"
                  variant="primary btn-bg button-style shadow hover:bg-dynamicBackBtn hover:opacity-90"
                  @onClick="isOpenEmployees = true"
                />
              </div>
            </template>
          </CardFooter>
      </c-card>

      <c-table
        :headers="headers"
        :items="trackingRegister"
        aria-label="leave plan table"
        :loading="isFetching"
        :has-number="false"
        v-if="trackingRegister.length || isFetching"
      >
        <template v-slot:item="{ item }">
          <div v-if="item.employeeInfo" class="flex justify-start items-start gap-2">
            <div>
              <span v-if="item.data.photo" class="flex justify-center items-center w-9 h-9">
                <img :src="item.data.photo" class="w-9 h-9 rounded" alt="profile photo" />
              </span>
              <div v-else class="flex justify-center items-center w-9 h-9 rounded border">
                <span class="text-blueCrayola text-center font-semibold text-lg p-2">
                  {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                </span>
              </div>
            </div>
            <div class="flex flex-col justify-start gap-1">
              <span class="font-semibold text-darkPurple text-base">
                {{ item.data.fname }} {{ item.data.lname }}
              </span>
              <span class="font-normal text-romanSilver text-xs uppercase">
                {{ item.data.userDesignation && item.data.userDesignation.name }}
              </span>
            </div>
          </div>
          <span v-if="item.employeeOffice" class="text-darkPurple text-sm">
            {{ item.data.employeeOffice && item.data.employeeOffice.name }}
          </span>
          <div v-if="item.status" class="rounded-md p-2 text-sm w-28 text-center capitalize"
            :class="{
              'bg-green-100 text-mediumSeaGreen': item.data.status === 'active',
              'bg-red-100 text-flame': item.data.status === 'inactive',
            }"
          >
            {{ item.data.status }}
          </div>
          <c-menu v-if="item.settingId" left @click.stop="">
            <template v-slot:title><icon icon-name="more_icon" size="xs" /></template>
            <div class="flex flex-col w-38 h-18 p-2 justify-start items-start">
              <!-- <div class="w-full" @click="payload = { ...payload, ...item.data }, isOpenEmployees = true">
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                    <icon icon-name="edit" class-name="text-blueCrayola" size="xs" />
                    <span class="text-darkPurple text-sm leading-5 font-normal">Edit</span>
                  </div>
                </div>
              </div> -->
              <div class="w-full" @click="onActivateClockInOut(item.data)">
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                    <icon v-if="item.data.status === 'active'" icon-name="icon-terminate" class-name="text-desire" size="xs" />
                    <icon v-else icon-name="icon-check-round" class-name="text-mediumSeaGreen" size="xs" />
                    <span class="text-darkPurple text-sm leading-5 font-normal">
                      {{ item.data.status === 'active' ? 'Deactivate' : 'Activate' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </c-menu>
        </template>
      </c-table>

      <div v-else class="flex flex-col justify-center items-center gap-0 px-10">
        <icon icon-name="paygrade_Illustration" size="" class-name="w-56 h-56" />
        <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
          Time tracking settings can be found here. This is empty now,
          but you can start adding time tracking settings to populate this area.
        </p>
      </div>

      <RightSideBar
        v-if="isOpenEmployees"
        @close="isOpenEmployees = false"
        :loading="isSubmitting"
        @submit="onSubmit()"
        submit="Save"
        width="width:520px"
        button-class="bg-dynamicBackBtn text-white rounded"
      >
        <template v-slot:title>
          <span class="font-bold text-lg text-darkPurple">Automatic Clock Ins</span>
        </template>

        <div class="flex flex-col justify-start gap-8">
          <div class="w-full flex flex-col justify-start items-start gap-4 border-b border-romanSilver border-dashed pb-4">
            <v-select
              :clearable="false"
              class="style-select w-full"
              :options="locationOptions"
              :close-on-select="true"
              placeholder="Select Location(s)"
              @input="onSelectLocations($event)"
            >
              <template #option="{ name }">
                <span class="font-bold text-base cursor-pointer">{{ name }}</span>
              </template>
              <template #selected-option="{ name }"><span>{{ name }}</span></template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes"><icon icon-name="caret-down" size="xsm" /></span>
              </template>
            </v-select>
            <div v-if="locations.length" class="flex justify-between items-center gap-4">
              <div v-for="location in locations" :key="location.id">
                <div class="flex justify-start items-center gap-0">
                  <c-checkbox v-model="location.checked" @change="onSelectLocations(location)" />
                  <p class="text-base text-jet">{{ location.name }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex flex-col justify-start items-start gap-6">
            <p class="text-darkPurple">Select employees for automatic clock-in</p>
            <div class="w-full flex justify-between items-center relative">
              <search-input
                class="w-full search-input"
                @input="$queryBuilder({ search: $event }, getOrganisationEmployees)"
                placeholder="Search Employee(s) and filter."
                v-model="temp.searchPhrase"
              />
              <span @click="onFilter()" class="absolute right-4 cursor-pointer">
                <icon icon-name="sort_size" size="xs" />
              </span>
            </div>
          </div>
          <c-loader v-if="isFetchingEmployees" size="xxs" :loader-image="false" />
          <template v-else>
            <div class="text-sm font-semibold text-jet">
              {{ employees.length }} Selected
            </div>
            <div>
              <div v-for="employee in employeesOptions" :key="employee.id"
                class="w-full flex flex-col justify-start items-start gap- border-b border-romanSilver border-dashed py-4"
              >
                <div class="w-full flex justify-between items-center">
                  <div class="flex justify-start items-start gap-2">
                    <div>
                      <span v-if="employee.photo" class="flex justify-center items-center w-11 h-11">
                        <img :src="employee.photo" class="w-11 h-11 rounded" alt="profile photo" />
                      </span>
                      <div v-else class="flex justify-center items-center w-11 h-11 rounded border">
                        <span class="text-blueCrayola text-center font-semibold text-lg p-2">
                          {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
                        </span>
                      </div>
                    </div>
                    <div class="flex flex-col justify-start gap-1">
                      <p class="font-semibold text-darkPurple text-base">
                        {{ employee.fname }} {{ employee.lname }}
                      </p>
                      <div class="flex justify-start items-center gap-2">
                        <p class="font-bold text-xs text-romanSilver uppercase">
                          {{ employee.orgFunction && employee.orgFunction.name }}
                        </p>
                        <p class="font-semibold text-xs text-romanSilver uppercase">
                          • {{ employee.userDesignation && employee.userDesignation.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <c-checkbox v-model="employee.checked" @change="onCheckedEmployee(employee)" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </RightSideBar>

      <c-modal v-if="isOpenActivate">
        <c-card class="flex flex-col gap-5 w-96 py-3 px-5">
          <p class="text-jet text-base font-bold border-b border-solid border-romanSilver">Action Required!</p>
          <div class="flex flex-col justify-start items-start gap-0">
            <p class="text-jet text-base font-semibold">
              Do you want to {{ selectedItem.status === 'active' ? 'deactivate' : 'activate' }} this time tracking?
            </p>
          </div>
          <div class="w-full flex justify-center gap-4">
            <c-button
              :disabled="isSubmitting"
              variant="primary btn-bg c-button capitalize whitespace-nowrap"
              :label="isSubmitting ? 'Please wait...' : selectedItem.status === 'active' ? 'Deactivate' : 'Activate'"
              @onClick="onActivateClockInOut(selectedItem)"
            />
            <c-button
              variant="secondary c-button btn-border"
              label="Cancel"
              :disabled="isSubmitting"
              @onClick="isOpenActivate = false"
            />
          </div>
        </c-card>
      </c-modal>

      <FilterTable
        v-if="isOpenFilter"
        :show-search="false"
        :filter-data="filterOptions"
        @close="isOpenFilter = false"
        @applyFilter="onSubmitFilter($event)"
      />
    </div>
  </template>

  <script>
  import VSelect from "vue-select"
import "vue-select/dist/vue-select.css"
  import CButton from "@scelloo/cloudenly-ui/src/components/button"
  import CLoader from "@scelloo/cloudenly-ui/src/components/loader"
  import CTable from "@scelloo/cloudenly-ui/src/components/table"
  import CCard from "@scelloo/cloudenly-ui/src/components/card"
  import CMenu from "@scelloo/cloudenly-ui/src/components/Menu"
  import CCheckbox from "@scelloo/cloudenly-ui/src/components/checkbox"
  import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input"
  import CardFooter from "@/components/CardFooter"
  import FilterTable from "@/components/FilterTable"
  import RightSideBar from "@/components/RightSideBar"
  import CModal from "@/components/Modal"

  export default {
    components: {
      VSelect,
      CMenu,
      CLoader,
      CButton,
      CCard,
      CardFooter,
      CTable,
      FilterTable,
      CModal,
      CCheckbox,
      SearchInput,
      RightSideBar
    },
    data() {
      return {
        temp: {},

        isOpenFilter: false,
        isFetching: true,
        setQueryTimeout: null,
        isOpenActivate: false,
        isSubmitting: false,
        isOpenEmployees: false,
        isFetchingEmployees: false,
        trackingStatus: null,
        trackingRegister: [],
        trackingMeta: {},
        queryParams: {},
        leavePlanOptions: [],
        employeesOptions: [],
        employees: [],
        activeEmployee: {},
        locationOptions: [],
        designationOptions: [],
        levelOptions: [],
        functionOptions: [],
        employmentTypeOptions: [],
        locations: [],
        filterOptions: [],
        checkedEmployees: [],
        headers: [
          { title: "Employee Name", value: "employeeInfo" },
          { title: "Location(s)", value: "employeeOffice" },
          { title: "Status", value: "status" },
          { title: "", value: "settingId", image: true },
        ],

        payload: {
          settingId: null,
          status: 'inactive',
          type: 'auto',
          locationIds: [],
          userIds: [],
        }
      };
    },
    methods: {
      onSubmit() {
        this.isSubmitting = true

        const payload = {
          ...this.payload,
          settingId: this.payload.settingId,
          userIds: this.payload.userIds?.map(v => v.userId),
          locationIds: this.payload.locationIds?.map(u => u.id),
        }

        if (payload.settingId) {
          this.$_patchSettingTimeTracking(payload).then(({ data }) => {
            this.$toasted.success(data.message, { duration: 5000 })
            this.getSettingTimeTracking()
            this.onClose()
          }).catch((error) => {
            this.$toasted.error(error, { duration: 5000 })
            this.isSubmitting = false
          })
        } else {
          this.$_postSettingTimeTracking(payload).then(({ data }) => {
            this.$toasted.success(data.message, { duration: 5000 })
            this.getSettingTimeTracking()
            this.onClose()
          }).catch((error) => {
            this.$toasted.error(error, { duration: 5000 })
            this.isSubmitting = false
          })
        }
      },
      onCheckedEmployee(checked) {
        const foundIndex = this.payload.userIds.findIndex(v => v.userId === checked.userId)
        if (checked.checked && foundIndex === -1) this.payload.userIds.push(checked)
        else this.payload.userIds = this.payload.userIds.filter((employee1) => (
          employee1.userId !== checked.userId
        ))
      },
      onClose() {
        this.isSubmitting = false
        this.isLoading = false
        this.isFetching = false
        this.isOpenFilter = false
        this.isOpenActivate = false
        this.isOpenEmployees = false
        this.isFetchingEmployees = false
        Object.assign(this.$data.payload, this.$options.data().payload)
      },
      onFilter(){
        this.filterOptions = [
          { header: 'Function', value: 'functionId', optionsBox: this.functionOptions },
          { header: 'Designation', value: 'designationId', optionsBox: this.designationOptions },
          { header: 'Job Level', value: 'levelId', optionsBox: this.levelOptions },
          { header: 'Employment Type', value: 'employmentType', optionsBox: this.employmentTypeOptions },
        ]
        this.isOpenFilter = true
      },
      onSubmitFilter(options){
        const filterOptions = options.reduce((r, o) => Object.assign(r, o), {})
        this.$queryBuilder(filterOptions, this.getOrganisationEmployees)
        this.isOpenFilter = false
      },
      onSelectLocations(location) {
        const foundIndex = this.locations.findIndex(el => el.id === location.id)
        if (foundIndex === -1) this.locations.push({ ...location, checked: true })

        const locationIds = this.locations?.filter(loc => loc.checked)?.map(vc => vc.id)?.join(',')
        this.$queryBuilder({ locationId: locationIds }, this.getOrganisationEmployees)
      },
      async onActivateClockInOut(selectedItem) {
        if (!this.isOpenActivate) {
          this.selectedItem = selectedItem
          this.isOpenActivate = true
          return
        }

        this.isSubmitting = true

        const payload = {
          settingId: this.selectedItem.settingId,
          status: this.selectedItem.status === 'active' ? 'inactive' : 'active'
        }

        this.$_setStatusSettingTimeTracking(payload).then(({ data }) => {
          this.$toasted.success(data.message, { duration: 5000 })
          this.getSettingTimeTracking()
          this.onClose()
        }).catch((error) => {
          this.$toasted.error(error, { duration: 5000 })
          this.isSubmitting = false
        })
      },
      async getSettingTimeTracking(params) {
        this.isFetching = true

        const payload = { type: this.payload.type, ...params }
        await this.$_getSettingTimeTracking(payload).then(({ data }) => {
          this.trackingRegister = data.employees.map(item => ({
            ...item, ...item.employeeInfo
          }))
          this.trackingMeta = data.meta || {}
          this.isFetching = false
        }).catch((error) => {
          this.$toasted.error(error, { duration: 5000 })
          this.isFetching = false
        })
      },
      async getOrganisationEmployees(params) {
        this.isFetchingEmployees = true
        this.temp.searchPhrase = params.search
        await this.$_getOrganisationEmployees(params).then(({ data }) => {
          const employeeIds = this.trackingRegister.map(employee => employee.userId)
          this.employeesOptions = data.data.employees.filter(i => !employeeIds.includes(i.userId))
          this.isFetchingEmployees = false
        })
      },

      async bootstrapModule(){
        await this.getSettingTimeTracking()
        await this.$queryBuilder({}, this.getOrganisationEmployees)
        this.$_getHumanarLocations().then(({ data }) => { this.locationOptions = data.outlets })
        this.$_getDesignations('').then(({ data }) => { this.designationOptions = data.designations })
        this.$_getLevels().then(({ data }) => { this.levelOptions = data.levels })
        this.$_getFunction().then(({ data }) => { this.functionOptions = data.functions })
        this.$_getHumanarEmploymentTypes().then(({ data }) => { this.employmentTypeOptions = data.result })
      }
    },
    async created() {
      this.bootstrapModule()
    }
  };
  </script>

  <style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
}
.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}
.c-button {
  width: 100% !important;
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}

::v-deep .search-input input {
  width: 85% !important;
}
  </style>
